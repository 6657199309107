@import 'src/scss/vars/colors.scss';
@import 'src/scss/vars/layout.scss';

$name: 'AppHeader';

.#{$name} {
  display: flex;
  align-items: center;
  gap: 2rem;
  box-sizing: border-box;
  height: calc($header-height + 2rem);
  width: 100%;
  min-width: $app-min-width;
  max-width: $app-max-width;
  margin: auto;
  padding: 2rem $app-vertical-margins 0 $app-vertical-margins;
  color: $white;

  &--detached {
    .#{$name} {
      &__background {
        opacity: 1;
      }
    }
  }

  &__background {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    z-index: 0;
    height: 8rem;
    background-image: linear-gradient($purple, $purple_l_15);
    transition: opacity 200ms ease-out;
    opacity: 0;
  }

  &__logo,
  &__navigation,
  &__contact {
    z-index: 1;
    display: flex;
    align-items: center;
    height: 100%;
    user-select: none;
  }

  &__logo {
    flex: 1;
    justify-content: flex-start;
    gap: 1.5rem;
    font-weight: 300;
    font-size: 2rem;
    letter-spacing: 0.1625rem;

    svg {
      height: 3rem;
    }
  }

  &__navigation {
    justify-content: center;
    gap: 2.5rem;
    margin: auto;
  }

  &__contact {
    flex: 1;
    justify-content: flex-end;
  }

  &__nav-link {
    padding: 1rem;
    font-weight: 300;
    font-size: 1.25rem;
    color: $white;
    cursor: pointer;
    text-decoration: none;
    opacity: 0.9;

    &:hover {
      text-decoration: underline;
      text-underline-offset: 0.25rem;
    }

    &:visited {
      color: unset;
    }
  }
}
