@import 'src/scss/vars/colors.scss';
@import 'src/scss/vars/layout.scss';

$name: 'App';

.#{$name} {
  height: 100%;
  width: 100%;
  min-width: $app-min-width;
  max-width: $app-max-width;
  margin: auto;

  &__content {
    display: flex;
    flex-direction: column;
    min-height: 100%;
    width: 100%;
  }

  &__header {
    position: sticky;
    z-index: 1000;
    top: -2rem;
    left: 0;
    right: 0;
    width: 100%;
  }

  &__body {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 2rem 0 0 0;
    min-height: $app-body-min-height;
  }

  &__footer {
    width: 100%;
  }
}
