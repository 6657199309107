@import 'src/scss/vars/colors.scss';

// Use normalize.css as a reset sheet
@import-normalize;

html {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
}

body {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  background-color: $black;
  overscroll-behavior: none;
  font-family: 'Red Hat Text', sans-serif;
}

#root {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
}

a,
a:visited,
a:hover,
a:active {
  text-decoration: none;
  color: inherit;
}

button,
input,
textarea {
  font-family: 'Red Hat Text', sans-serif;
}

.monospace {
  font-family: 'Roboto Mono', monospace;
}
