@import 'src/scss/vars/colors.scss';

$name: 'LoadingSpinner';

.#{$name} {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  position: relative;

  &--small {
    width: 3rem;
    height: 3rem;

    .#{$name} {
      &__dot {
        top: 1.125rem;
        width: 0.5rem;
        height: 0.5rem;

        &:nth-child(1) {
          left: 0rem;
          animation: dot1 0.6s infinite;
        }

        &:nth-child(2) {
          left: 0rem;
          animation: dot2--small 0.6s infinite;
        }

        &:nth-child(3) {
          left: 1.25rem;
          animation: dot2--small 0.6s infinite;
        }

        &:nth-child(4) {
          left: 2.5rem;
          animation: dot3 0.6s infinite;
        }
      }
    }
  }

  &--medium {
    width: 5rem;
    height: 5rem;

    .#{$name} {
      &__dot {
        top: 2rem;
        width: 0.75rem;
        height: 0.75rem;

        &:nth-child(1) {
          left: 0rem;
          animation: dot1 0.6s infinite;
        }

        &:nth-child(2) {
          left: 0rem;
          animation: dot2--medium 0.6s infinite;
        }

        &:nth-child(3) {
          left: 2.125rem;
          animation: dot2--medium 0.6s infinite;
        }

        &:nth-child(4) {
          left: 4.25rem;
          animation: dot3 0.6s infinite;
        }
      }
    }
  }

  &--large {
    width: 7rem;
    height: 7rem;

    .#{$name} {
      &__dot {
        top: 2.875rem;
        width: 1rem;
        height: 1rem;

        &:nth-child(1) {
          left: 0rem;
          animation: dot1 0.6s infinite;
        }

        &:nth-child(2) {
          left: 0;
          animation: dot2--large 0.6s infinite;
        }

        &:nth-child(3) {
          left: 3rem;
          animation: dot2--large 0.6s infinite;
        }

        &:nth-child(4) {
          left: 6rem;
          animation: dot3 0.6s infinite;
        }
      }
    }
  }

  &__dot {
    position: absolute;
    border-radius: 50%;
    background: $white;
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
  }
}

@keyframes dot1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes dot3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}

@keyframes dot2--small {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(1.25rem, 0);
  }
}

@keyframes dot2--medium {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(2.125rem, 0);
  }
}

@keyframes dot2--large {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(3rem, 0);
  }
}
